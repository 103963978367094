// src/components/PaymentForm.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Typography, Container, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PersonAdd from '@mui/icons-material/PersonAdd';
import dayjs from 'dayjs';
import api from '../utils/api';

const PaymentForm = () => {
  const [student, setStudent] = useState(null);
  const [existingPayment, setExistingPayment] = useState(null);
  const [students, setStudents] = useState([]);
  const [amount, setAmount] = useState('');
  const [representing, setRepresenting] = useState('');
  const [excluded, setExcluded] = useState(false);
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss')); // Read-only field
  const navigate = useNavigate();

  const { id, idPayment } = useParams();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await api.get('/students');
        setStudents(response.data);
        if (id) {
          const student = response.data.find(studentFromApi => studentFromApi.id === parseInt(id, 10));
          setStudent(student);
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, [id]);

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await api.get(`/payments/${idPayment}`);
        setExistingPayment(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (idPayment) {
      fetchPayment();
    }
  }, [idPayment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('student', student.id);
    formData.append('amount', amount);
    formData.append('representing', representing);
    formData.append('excluded', excluded === true ? 1 : 0);
    formData.append('date', date);

    if (idPayment) {
      api.post(`/payments/${idPayment}/edit`, formData).then(() => navigate('/payments'));
    } else {
      api.post('/payments/new', formData).then(() => navigate('/payments'));
    }
  };

  useEffect(() => {
    if (existingPayment) {
      setStudent(existingPayment.student);
      setAmount(existingPayment.amount);
      setRepresenting(existingPayment.representing);
      setDate(existingPayment.date); // Read-only field
      setExcluded(existingPayment.excluded);
    }
  }, [existingPayment]);

  const handleExcludedChange = (event) => {
    setExcluded(event.target.checked); // Update the "excluded" state based on checkbox status
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '1em' }}>
        <Typography variant="h5" gutterBottom>
          {existingPayment ? 'Edit Payment' : 'Add Payment'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} display="flex" justifyContent="center" alignItems="center" size="grow">
            <Grid item xs={9}>
                <Autocomplete
                  options={students}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  value={student}
                  onChange={(event, newValue) => setStudent(newValue)}
                  renderInput={(params) => <TextField {...params} label="Student" variant="outlined" required />}
                />
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="secondary" type="submit" component={Link} to={`/add-student`}>
                  <PersonAdd /> &nbsp; Add
                </Button>
            </Grid>
          </Grid>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Amount"
                fullWidth
                variant="outlined"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Representing"
                fullWidth
                variant="outlined"
                value={representing}
                onChange={(e) => setRepresenting(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Date"
                fullWidth
                variant="outlined"
                value={date}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={excluded}
                    onChange={handleExcludedChange}
                    color="primary"
                  />
                }
                label="Excluded"
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Save Payment
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default PaymentForm;
