// src/pages/Payments.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from '@mui/material';
import AddCard from '@mui/icons-material/AddCard';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import { Edit, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import api from '../utils/api';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ConfirmDialog from '../components/ConfirmDialog';

const locations = ['Dristor', 'Militari', 'Arcul de Triumf'];

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

const Payments = () => {
  const navigate = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const [filteredPayments, setFilteredPayments] = useState([]);
  const [payments, setPayments] = useState([]);
  const [students, setStudents] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const [filters, setFilters] = useState({
    student: null,
    location: '',
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await api.get('/students/');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const response = await api.get('/settlements/');
        const settlements = response.data;
        if (settlements.length > 0) {
          settlements.forEach((settlement) => {
            settlement.decodedRepresenting = JSON.parse(settlement.representing);
            settlement.lastDate = settlement.decodedRepresenting[0].date;
            settlement.firstDate =
              settlement.decodedRepresenting[settlement.decodedRepresenting.length - 1].date;
          });
          setSettlements(settlements);
        }
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchSettlements();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await api.get('/payments/');
        setPayments(response.data);
        setFilteredPayments(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, []);

  const applyFilters = () => {
    let filtered = payments;

    if (filters.student) {
      filtered = filtered.filter((payment) => payment.student.id === filters.student.id);
    }
    if (filters.location) {
      filtered = filtered.filter((payment) => payment.student.mainLocation === filters.location);
    }
    if (filters.startDate) {
      filtered = filtered.filter((payment) =>
        dayjs(payment.date).isAfter(dayjs(filters.startDate))
      );
    }
    if (filters.endDate) {
      filtered = filtered.filter((payment) => dayjs(payment.date).isBefore(dayjs(filters.endDate)));
    }

    setFilteredPayments(filtered);
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line
  }, [filters]);

  const handleSettlement = () => {
    const startDate =
      settlements.length > 0
        ? Date.parse(settlements[0].decodedRepresenting[0].date)
        : Date.parse(payments[payments.length - 1].date);

    let filtered = payments.filter((payment) => payment.excluded === false && Date.parse(payment.date) > startDate);

    const amount = filtered.reduce((acc, payment) => acc + parseFloat(payment.amount), 0);
    const representing = filtered.map((payment) => {
      return {
        name: `${payment.student.firstName} ${payment.student.lastName}`,
        amount: payment.amount,
        date: payment.date,
        representing: payment.representing
      };
    });

    if (amount > 0) {
      const settlement = {
        dateFrom: new Date(startDate),
        dateTo: dayjs().format('YYYY-MM-DD HH:mm'),
        amount,
        representing: JSON.stringify(representing)
      };
      try {
        api.post('/settlements/new', settlement).then(() => navigate('/settlements'));
      } catch (error) {
        console.error('Error adding settlement:', error);
      }
    }
  };

  const handleDeleteClick = (payment) => {
    setIdToDelete(payment);
    setConfirmOpen(true);
  };

  const deletePayment = async () => {
    if (idToDelete) {
      try {
        await api.post(`/payments/${idToDelete}/delete`);
        setFilteredPayments(filteredPayments.filter((payment) => payment.id !== idToDelete));
        setConfirmOpen(false);
      } catch (error) {
        console.error('Error deleting student:', error);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Payments
      </Typography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                options={students}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                value={filters.student}
                onChange={(event, newValue) => setFilters({ ...filters, student: newValue })}
                renderInput={(params) => <TextField {...params} label="Student" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                options={locations}
                getOptionLabel={(option) => option}
                value={filters.location}
                onChange={(event, newValue) => setFilters({ ...filters, location: newValue })}
                renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Start Date"
                type="date"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={filters.startDate}
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="End Date"
                type="date"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={filters.endDate}
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider variant="middle" flexItem />
        </Grid>
        <Grid item xs={8}>
          <Button variant="contained" color="secondary" onClick={handleSettlement}>
            + Settlement
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" component={Link} to="/add-payment" fullWidth>
          <AddCard /> &nbsp; New
          </Button>
        </Grid>
      </Grid>

      <List>
        {filteredPayments.map((payment, index) => (
          <>          
            <ListItem key={index}>
              <ListItemText
                primary={`${payment.student.firstName} ${payment.student.lastName} - ${payment.representing} - ${payment.amount} lei`}
                secondary={`${dayjs(payment.date).format('YYYY-MM-DD HH:mm:ss')}`}
              />
              <ListItemSecondaryAction>
                <IconButton component={Link} to={`/edit-payment/${payment.id}`}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(payment.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider key={`${index}-1`} variant="middle" flexItem />
          </>
        ))}
      </List>

      <ConfirmDialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={deletePayment}
        title="Delete Payment"
        description="Are you sure you want to delete this payment?"
      />
    </Container>
  );
};

export default Payments;
